import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import fontStyles from '../../../../common/fontStyles';
import ExpandablePicker from '../ExpandablePicker';
import { EditButton } from '../ExpandButtons';
import colors from '../../../../common/colors';
import { SoldOutBadge } from '../../../../coreComponents/boxes/Badge';
import CalorieDisplay from '../CalorieDisplay';
import { onSCCFEditButtonClick } from '@/dataAnalytics/dataAnalyticsHelper';
import { SCCFSection, SCCFSectionAction } from '@/dataAnalytics/analyticsTypes';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { PickerType } from '../ExpandablePicker/ExpandablePicker';

const useStyles = makeStyles(() => ({
  card: {
    ...fontStyles.textLink,
    minHeight: '64px',
    boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    width: '100%'
  },
  cardText: {
    ...fontStyles.bodyPriceAndCalorie,
    paddingLeft: '16px'
  },
  cardAction: {
    alignSelf: 'auto',
    height: '100%',
    margin: 0,
    marginTop: '-8px'
  },
  cardAvatar: {
    width: '64px',
    height: '64px',
    margin: 0
  },
  expandedContainer: {
    padding: '18px 16px 24px 16px'
  },
  image: {
    height: '64px',
    width: '64px',
    '&.MuiAvatar-colorDefault': {
      textAlign: 'center'
    }
  },
  collapse: {
    borderTop: `solid 1px ${colors.gray400}`
  }
}));

export interface SCCFPickerProps {
  nutrition: Partial<Nutrition> | undefined;
  selectedItem: string | null | undefined | JSX.Element;
  imgUrl: string | undefined;
  children?: JSX.Element;
  testId: string;
  label: SCCFSection;
  outOfStock?: boolean;
  editable?: boolean;
  pickerType?: PickerType | undefined;
  onExpanded?: () => void;
  onCollapsed?: () => void;
}

const SCCFPicker = ({
  pickerType,
  selectedItem,
  imgUrl,
  children,
  testId,
  outOfStock,
  label,
  nutrition,
  editable = true,
  onExpanded = () => { },
  onCollapsed = () => { }
}: SCCFPickerProps): JSX.Element => {
  const classes = useStyles();

  const analytics = useAnalytics();
  const { pizza, deal } = analytics.analyticsDataModel;

  const { isLineup } = useLineup();
  interface SCCFSectionData {
    [SCCFSection.FLAVOR]: {
      analyticsAction: SCCFSectionAction.FLAVOR;
    };
    [SCCFSection.SAUCE]: {
      analyticsAction: SCCFSectionAction.SAUCE;
    };
    [SCCFSection.CHEESE]: {
      analyticsAction: SCCFSectionAction.CHEESE;
    };
  }

  const SccfSectionData: SCCFSectionData = {
    [SCCFSection.SAUCE]: {
      analyticsAction: SCCFSectionAction.SAUCE
    },
    [SCCFSection.CHEESE]: {
      analyticsAction: SCCFSectionAction.CHEESE
    },
    [SCCFSection.FLAVOR]: {
      analyticsAction: SCCFSectionAction.FLAVOR
    }
  };

  const addAnalytics = (
    expanded: boolean
  ) => expanded && analytics.push(() => onSCCFEditButtonClick(
    pizza.name || '',
    SccfSectionData[label].analyticsAction,
    deal.deal_name,
    deal.deal_id,
    isLineup
  ));

  const onExpandButton = (
    expanded: boolean
  ): JSX.Element | null => (editable ? <EditButton expanded={expanded} ariaHidden /> : null);

  const caloriesDisplay = (
    <CalorieDisplay label={label} nutrition={nutrition} includeProductName={false} />
  );

  const ariaExpandBtn = (expanded: boolean) => ({
    role: 'button',
    'aria-expanded': expanded
  });

  return (
    <ExpandablePicker
      expandButton={!outOfStock ? onExpandButton : () => <SoldOutBadge />}
      onExpanded={onExpanded}
      onCollapsed={onCollapsed}
      enabled={!outOfStock && editable}
      heading={!outOfStock ? selectedItem : null}
      subheading={caloriesDisplay}
      expandedContent={children}
      classes={classes}
      testId={testId}
      imgUrl={imgUrl}
      providedAriaRole={ariaExpandBtn}
      pushSCCFEditButtonAnalytics={addAnalytics}
      pickerType={pickerType}
      editable={editable}
    />
  );
};

export default SCCFPicker;
