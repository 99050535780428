import { createStyles, makeStyles } from '@material-ui/core';
import {
  extraSmallMobileStartBreakpoint, largeStartBreakpoint, mobileStartBreakpoint, smallMobileStartBreakpoint
} from '@/materialUi/theme';

const useCrustItemWithDescriptionStyles = makeStyles((theme) => createStyles({
  crustContainer: {
    width: '48%',
    marginTop: '10px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    }
  },
  crustInfoContainer: {
    height: '100px',
    overflow: 'hidden',
    borderRadius: '8px 0 0 8px',
    [theme.breakpoints.down(386)]: {
      height: '135px'
    },
    [theme.breakpoints.down(extraSmallMobileStartBreakpoint)]: {
      height: 'unset'
    },
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      display: 'grid',
      gridAutoFlow: 'column'
    }
  },
  crustImage: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      display: 'flex'
    },
    [theme.breakpoints.down(386)]: {
      width: '160px'
    },
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      width: '79px'
    },
    '& img': {
      maxHeight: '100%',
      borderTopLeftRadius: '8px',
      [theme.breakpoints.down(extraSmallMobileStartBreakpoint)]: {
        marginLeft: '-50px',
        width: '140px',
        height: '140px'
      },
      [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
        marginLeft: '-36px'
      },
      [theme.breakpoints.up(403)]: {
        marginLeft: 'unset'
      },
      [theme.breakpoints.up(largeStartBreakpoint)]: {
        height: '100%',
        marginLeft: '-21px'
      }
    }
  },
  crustInfo: {
    padding: '8px 0 0 10px',
    width: '100%'
  },
  crustOutOfStock: {
    opacity: 0.5
  },
  newIcon: {
    marginRight: '8px'
  }

}));

export default useCrustItemWithDescriptionStyles;
