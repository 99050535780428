import { Modifier } from '@pizza-hut-us-development/client-core';

function getRecipeUpchargeAmount(options: Modifier[]) {
  const sizeOptions = options?.find((option) => option.id?.includes('size'));

  const crustModifiers = sizeOptions?.modifiers?.flatMap((mod) => mod.modifiers?.filter(((modifier) => modifier?.variantCode)));

  const lowestUpchargeAmount = crustModifiers?.reduce((acc, curr) => Math.min(curr?.upcharge ?? Infinity, acc), Infinity) || 0;

  return lowestUpchargeAmount === Infinity ? 0 : lowestUpchargeAmount;
}

function getSizeUpchargeAmount(size: Modifier, options: Modifier[]) {
  const recipeUpchargeAmount = getRecipeUpchargeAmount(options);
  let sizeUpchargeAmount: number = size?.modifiers?.reduce(
    (acc, curr) => Math.min(curr?.upcharge ?? Infinity, acc), Infinity
  ) || 0;

  sizeUpchargeAmount = sizeUpchargeAmount === Infinity ? 0 : sizeUpchargeAmount;

  return sizeUpchargeAmount - recipeUpchargeAmount;
}

function getCrustUpchargeAmount(crust: Modifier, options: Modifier[]) {
  const recipeUpchargeAmount = getRecipeUpchargeAmount(options);
  const sizeOptions = options?.find((option) => option.id?.includes('size'));
  const sizeForCrust = sizeOptions?.modifiers?.find((mod) => mod.modifiers?.some((modifier) => modifier?.variantCode === crust?.variantCode));
  const sizeUpchargeAmount = sizeForCrust ? getSizeUpchargeAmount(sizeForCrust, options) : 0;
  const variantUpchargeAmount = crust?.upcharge || 0;

  return variantUpchargeAmount - sizeUpchargeAmount - recipeUpchargeAmount;
}

export { getRecipeUpchargeAmount, getSizeUpchargeAmount, getCrustUpchargeAmount };
