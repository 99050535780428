import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Select } from '@material-ui/core';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import { useDecision } from '@optimizely/react-sdk';
import colors from '../../../common/colors';
import { updatePizzaSummary } from '../../../builders/pizza/slice/pizza.slice';
import { mobileStartBreakpoint } from '../../../materialUi/theme';
import { Pizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { RootState } from '@/rootStateTypes';
import { updateDeal } from '@/builders/deals/slice/deal.slice';
import { selectDeal } from '@/builders/deals/slice/selectors/dealsSelectors';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  quantitySelect: {
    borderRadius: '8px 0 0 8px',
    paddingLeft: '11px',
    border: `solid 1px ${colors.gray400}`,
    borderRight: '0',
    '&:focus': {
      borderRadius: '8px 0 0 8px'
    },
    height: '18px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      height: '25px'
    }
  },
  quantityBtn: {
    height: '32px',
    width: '58px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      height: '40px'
    }
  },
  icon: {
    right: '8px',
    color: colors.gray900
  },
  screenReadersOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap', /* added line */
    border: '0'
  }
}));

const getPizza = (state: RootState): Pizza => state.domain.pizza;
const quantitySelectOptions = Array.from(Array(10).keys())
  .map((num) => {
    const quantity = num + 1;
    return <option key={quantity} value={quantity}>{quantity}</option>;
  });

interface RecipeQuantitySelectorProps {
  quantity?: number;
  isDeal?: boolean;
}

const RecipeQuantitySelector = ({ quantity = 1, isDeal = false }: RecipeQuantitySelectorProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pizza = useSelector(getPizza);
  const deal = useSelector(selectDeal);
  const [{
    enabled: isSingleStepPizzaDealQuantityPickerEnabled, variationKey
  }] = useDecision('fr-dtg-218-quantity-picker-single-step-pizza-deal');

  const isSingleStepDealQtyPickerABTestOn = isSingleStepPizzaDealQuantityPickerEnabled && variationKey === 'control_qty_pick_pizza_deal_on';

  const ArrowDown = () => (<KeyboardArrowDownOutlinedIcon className={`${classes.icon} MuiSelect-icon`} />);

  const handleQuantityChange = (event?: React.ChangeEvent<{ value: string | unknown }>) => {
    let selectedQuantity = parseInt(event?.target.value as string ?? '', 10);

    if (Number.isNaN(selectedQuantity)) {
      selectedQuantity = 1;
      console.error('Unable to parse selected quantity');
    }

    if (isSingleStepDealQtyPickerABTestOn && isDeal && deal) {
      dispatch(updateDeal({
        ...deal.data,
        quantity: selectedQuantity
      }));
    } else {
      dispatch(updatePizzaSummary({
        id: pizza.id,
        name: pizza.name,
        splittable: pizza.splittable,
        price: pizza.price,
        quantity: selectedQuantity
      }));
    }
  };

  return (
    <>
      <Select
        native
        defaultValue={quantity.toString()}
        className={classes.quantityBtn}
        onChange={handleQuantityChange}
        disableUnderline
        IconComponent={ArrowDown}
        inputProps={{
          name: 'quantity',
          'aria-label': 'quantity',
          id: 'pizza-quantity-select',
          className: classes.quantitySelect,
          tabIndex: 0
        }}
      >
        {quantitySelectOptions}
      </Select>
    </>
  );
};

export default RecipeQuantitySelector;
