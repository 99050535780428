import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { crustCaloriesBySize } from '@/builders/pizza/hooks/pizzaIngredientOptionsSelectors';
import { extractGlobalId } from '@/builders/pizza/identifiers';
import getCaloriesPerUnitText from '@/builders/pizza/dataTransformers/caloriesPerUnit';
import formattedPrice from '@/common/formattedPrice';
import { TCrust } from '../types';
import { usePizzaPricing } from '@/clientCore/menu/hooks/usePizzaPricing';

const useCrustSelection = ({ crust, isSelected }: TCrust) => {
  const {
    name, description, image, price, outOfStock
  } = crust;

  const selectedSize = useSelector(selectors.selectPizzaSize);

  const router = useRouter();
  const { crustUpcharges } = usePizzaPricing(router.asPath);

  /**
   * @function setCrustAddOnCurrency
   * @description formats the price integer to add a crust, if it's extra, into currency.
   */
  const setCrustAddOnCurrency = (crustPrice: number) => {
    const TWO_DECIMAL_PLACES = 100;

    const isDecimalBiggerThanZero = Boolean((crustPrice) % TWO_DECIMAL_PLACES);
    const priceFormatted = formattedPrice(crustPrice, !isDecimalBiggerThanZero);

    return `ADD ${priceFormatted}`;
  };

  // sets up logic to set the calories text based on the pizza size
  const getCrustCaloriesBySize = crustCaloriesBySize(extractGlobalId(selectedSize));
  const calories = getCrustCaloriesBySize(crust);

  // set data points to return for component
  const isCrustSelected = outOfStock ? false : isSelected;
  const addOnPrice = useMemo(() => {
    const dealCrustUpcharge = crustUpcharges?.find((crustUpcharge) => crustUpcharge.id === crust.id)?.upcharge;

    if (dealCrustUpcharge) {
      return setCrustAddOnCurrency(dealCrustUpcharge);
    }

    if (price && !outOfStock && dealCrustUpcharge !== 0) {
      return setCrustAddOnCurrency(price);
    }

    return '';
  }, [crustUpcharges, price, outOfStock, crust.id]);
  const caloriesText = getCaloriesPerUnitText(calories, '');
  const screenReaderText = [
    name, outOfStock && 'sold out', caloriesText, addOnPrice.toLowerCase()
  ].filter((info) => !!info).join(', ').trim();
  const images = {
    primary: image,
    fallback: '/images/nationalMenu/fallback-product-image-mobile.jpg'
  };

  return {
    name,
    description,
    images,
    outOfStock,
    isCrustSelected,
    addOnPrice,
    caloriesText,
    screenReaderText
  };
};

export default useCrustSelection;
