import React from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import {
  computeProductPrice,
  Modifier,
  usePriceCartMutation
} from '@pizza-hut-us-development/client-core';
import colors from '../../../common/colors';
import formattedPrice from '../../../common/formattedPrice';
import { selectors } from '../slice/pizza.slice';
import {
  cartTotalPriceSelectors,
  updateCurrentBuilderPrice,
  updatePriceIsLoading
} from './CartTotalPrice.slice';
import { useDeepEffectEfficiently } from '../../../common/useDeepEffectEfficiently';
import { useDebounceSelector } from '../../../common/useDebounceSelector';
import { RootState } from '@/rootStateTypes';
import useCCTransformLegacyPizzaBuilderOutputToPriceablePizza from '@/clientCore/temporaryTransformationalHooks/useCCTransformLegacyPizzaBuilderOutputToPriceablePizza';
import {
  useCCTransformLegacyCartItem
} from '@/clientCore/temporaryTransformationalHooks/useCCTransformLegacyCartItem/useCCTransformLegacyCartItem';

const useStyles = makeStyles({
  price: {
    paddingTop: '3px',
    color: (priceIsLoading) => (priceIsLoading ? colors.gray400 : colors.gray900)
  },
  loadingIndicator: {
    position: 'absolute',
    color: colors.gray601,
    left: '38%',
    marginTop: '-2px',
    width: '24px',
    height: '24px'
  },
  carToPriceContainer: {
    position: 'relative',
    minHeight: '34px',
    minWidth: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const getLocalizationToken = (state: RootState): StoreDetail['storeToken'] => state?.domain?.localization?.localizedStoreDetails?.storeToken;

const CartTotalPrice = (): JSX.Element | null => {
  const pizza = useDebounceSelector(selectors.selectCartAddablePizza);
  const pizzaOptions = useSelector(selectors.selectPizzaOptions);
  const shouldShowPrice = useSelector(selectors.showTotalPrice);
  const totalPriceInCents = useSelector(
    cartTotalPriceSelectors.selectTotalInCents
  );
  const priceIsLoading = useSelector(cartTotalPriceSelectors.selectIsLoading);
  const classes = useStyles(priceIsLoading);
  const localizationToken = useSelector(getLocalizationToken);
  const dispatch = useDispatch();
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const [enableHidePriceLoader] = useDecision('ops-web2153-hide-pb-loader-yum');
  const { handleLegacyPizzaBuilderPizzaToPriceableItem } = useCCTransformLegacyPizzaBuilderOutputToPriceablePizza();
  const pizzaToPrice = { ...pizza, quantity: 1, specialInstructions: '' };
  const [getPrice] = usePriceCartMutation();
  const { handleLegacyCartItemTransformer } = useCCTransformLegacyCartItem();

  const getYumPizzaPrice = (): number => {
    const { modifiers } = handleLegacyCartItemTransformer(pizzaToPrice) as Modifier;
    const selectedPizzaSize = pizzaOptions?.sizes?.find((option) => option.id === pizzaToPrice?.size?.id);
    const selectedPizzaCrust = pizzaOptions?.crusts?.find((option) => option.variantCode === pizzaToPrice?.crust?.variantCode);

    modifiers?.push({
      ...selectedPizzaSize,
      optionTypeCode: 'option_pizza_size',
      displayOrder: selectedPizzaSize?.priority
    } as Modifier,
    {
      ...selectedPizzaCrust,
      optionTypeCode: 'option_pizza_crust',
      displayOrder: selectedPizzaSize?.priority
    } as Modifier);

    return computeProductPrice(modifiers as Modifier[]);
  };

  const handleCCPHDPizzaBuilderPricing = async () => {
    // if yum ecomm gaurd return
    if (isYumEcomm) {
      dispatch(updateCurrentBuilderPrice({ totalPriceInCents: getYumPizzaPrice() || null }));
      dispatch(updatePriceIsLoading(false));

      return;
    }

    try {
      const ccPizza = handleLegacyPizzaBuilderPizzaToPriceableItem(pizzaToPrice);
      const newPrice = await getPrice({
        items: [ccPizza]
      });
      if ('error' in newPrice) throw newPrice.error;

      dispatch(
        updateCurrentBuilderPrice({ totalPriceInCents: newPrice.data.price })
      );
    } catch {
      dispatch(updateCurrentBuilderPrice({ totalPriceInCents: null }));
    } finally {
      dispatch(updatePriceIsLoading(false));
    }
  };

  useDeepEffectEfficiently(async () => {
    if (!shouldShowPrice) return Promise.resolve();
    dispatch(updatePriceIsLoading(true));

    // if using CC phd display pricing using cc hook
    return handleCCPHDPizzaBuilderPricing();
  }, [pizzaToPrice, localizationToken, shouldShowPrice]);

  if (!shouldShowPrice) return null;

  return (
    <div className={classes.carToPriceContainer}>
      {enableHidePriceLoader.enabled && isYumEcomm
        ? null
        : priceIsLoading && (
        <CircularProgress className={classes.loadingIndicator} size={24} />
        )}
      <Typography
        variant="h2"
        className={classes.price}
        aria-label="Cart total price"
      >
        {totalPriceInCents !== null && formattedPrice(totalPriceInCents)}
      </Typography>
    </div>
  );
};

export default CartTotalPrice;
