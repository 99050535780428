import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';
import { smallMobileStartBreakpoint } from '@/materialUi/theme';
import CenteredContainer from '../../../../common/CenteredContainer';
import fontStyles from '../../../../common/fontStyles';
import HeaderText from '../../../../common/HeaderText';
import BuilderSteps from '../../BuilderSteps';
import PizzaSummary from '../../steps/PizzaSummary';
import SpecialInstructionsSetterDesktop from '../../setters/SpecialInstructions/SpecialInstructionsSetterDesktop';
import {
  updatePizzaMeatToppings,
  updatePizzaVeggieToppings,
  selectors
} from '../../slice/pizza.slice';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import {
  EditBuilderSkeleton,
  useEditBuilderSkeleton
} from '@/common/EditBuilderSkeleton';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import SizeCrustSetterDesktop from '@/builders/pizza/setters/SizeCrust/SizeCrustSetterDesktop';
import SauceCheeseFlavorSetterDesktop from '@/builders/pizza/setters/SauceCheeseFlavor/SauceCheeseFlavorSetter/SauceCheeseFlavorSetterDesktop';
import ToppingsPicker, { renderToppingExtraPriceText } from '@/builders/pizza/setters/Toppings/ToppingsPicker';
import { useExtraToppingPricing } from '@/builders/pizza/hooks/pizzaPricing';
import { PizzaIngredient } from '@/builders/pizza/dataTransformers/builderTypes';
import useCrustSizeStepText from '../../steps/SizeCrust/useCrustSizeStepText';
import { usePizzaMeltDetector } from '@/hooks/usePizzaMeltDetector';
import { usePizzaPricing } from '@/clientCore/menu/hooks/usePizzaPricing';
import { RootState } from '@/rootStateTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '728px',
    flexGrow: 1,
    marginTop: '3.5em'
  },
  price: {
    ...fontStyles.h2Mobile,
    margin: '3px 23px 0 0'
  },
  builderStepContainerExpanded: {
    gap: '24px',
    '& *[role="radio"]': {
      margin: 0
    }
  },
  gridWidth: {
    width: '100%'
  },
  expandedCrust: {
    '& *[role="radiogroup"]': {
      justifyContent: 'start',
      gap: '24px',
      marginTop: '12px'
    }
  },
  expandedCrustHeading: {
    marginBottom: '24px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      marginTop: '24px'
    }
  },
  expandedToppings: {
    marginTop: '48px',
    maxWidth: '540px'
  },
  toppingsPriceSummary: {
    marginBottom: '24px'
  }
}));

export interface DesktopPizzaBuilderProps {
  step: BuilderSteps;
  setStep: (step: BuilderSteps) => void;
  currentPath?: string;
  recipes?: DealRecipe[];
}

const PizzaBuilderDesktopExpanded = (
  props: DesktopPizzaBuilderProps
): JSX.Element => {
  const {
    step, setStep, currentPath, recipes
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const summaryTitle = useSelector(selectors.selectPizzaSummaryTitle);
  const showEditBuilderSkeleton = useEditBuilderSkeleton();
  const isPizzaMelt = usePizzaMeltDetector();
  const selectedRecipe = useSelector(selectors.selectPizzaOptions);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const { toppingUpChargePrice, hasIncludedToppings } = usePizzaPricing(currentPath);
  const [{ enabled: newToppingUpchargeEnabled }] = useDecision('fr-dtg-1133-crust-upcharges-topping-upcharges');

  const yumToppingUpchargeEnabled = isYumEcomm && newToppingUpchargeEnabled;

  const toggleStep = (clickedStep: BuilderSteps) => {
    if (step === clickedStep) {
      setStep(BuilderSteps.MAIN);
    } else {
      setStep(clickedStep);
    }
  };

  const { subHeadingHasError, subHeading } = useCrustSizeStepText();
  const meatToppingsOptions = useSelector(selectors.selectMeatToppingOptions);
  const veggieToppingsOptions = useSelector(
    selectors.selectVeggieToppingsOptions
  );
  const selectedMeatToppings = useSelector(selectors.selectPizzaMeatToppings);
  const selectedVeggieToppings = useSelector(
    selectors.selectPizzaVeggieToppings
  );
  const selectRecipeDefaultToppingsCount = useSelector(
    selectors.selectRecipeDefaultToppingsCount
  );
  const isPizzaSizeSplittable = useSelector(selectors.isSplittable);

  const handleAddMeatToppings = (toppings: PizzaIngredient[]) => {
    dispatch(updatePizzaMeatToppings(toppings));
  };

  const handleAddVeggieToppings = (toppings: PizzaIngredient[]) => {
    dispatch(updatePizzaVeggieToppings(toppings));
  };
  const { meatPrice, veggiePrice } = useExtraToppingPricing();
  // if meatPrice and veggiePrice are the same just render one price (even if null). if different use null
  let toppingExtraPriceText = renderToppingExtraPriceText(
    meatPrice === veggiePrice ? meatPrice : null,
    selectRecipeDefaultToppingsCount
  );
  if (yumToppingUpchargeEnabled) {
    toppingExtraPriceText = renderToppingExtraPriceText(toppingUpChargePrice, hasIncludedToppings ? 1 : 0);
  }

  return (
    <CenteredContainer>
      <Grid className={classes.root} data-testid="build-your-pizza-desktop">
        <Grid item md={7}>
          <HeaderText
            text={
              recipes && recipes.length > 1
                ? selectedRecipe?.summary.displayName
                : summaryTitle
            }
          />
          <PizzaSummary text="Build your pizza" noBorder />
        </Grid>

        <Grid container wrap="nowrap">
          <Grid item className={classes.gridWidth}>
            <Grid className={classes.builderStepContainerExpanded}>
              {showEditBuilderSkeleton ? (
                <EditBuilderSkeleton />
              ) : (
                <>
                  {!isPizzaMelt && (
                    <Grid className={classes.expandedCrust}>
                      <Grid className={classes.expandedCrustHeading}>
                        <HeaderText text="Crust" />
                        <Typography color={subHeadingHasError ? 'error' : undefined}>
                          {subHeading}
                        </Typography>
                      </Grid>
                      <SizeCrustSetterDesktop
                        toggleStep={toggleStep}
                        currentPath={currentPath}
                      />
                    </Grid>
                  )}

                  <Grid className={classes.expandedToppings}>
                    <HeaderText text="Toppings" />
                    <Typography className={classes.toppingsPriceSummary} data-testid="toppings-extra-price">
                      {toppingExtraPriceText}
                    </Typography>
                    <ToppingsPicker
                      title="Meats"
                      toppingOptions={meatToppingsOptions}
                      selectedToppings={selectedMeatToppings}
                      onToppingsUpdate={handleAddMeatToppings}
                      isPizzaSizeSplittable={isPizzaSizeSplittable}
                      extraPrice={toppingUpChargePrice}
                      numDisplayedItems={meatToppingsOptions.length}
                      hideToppingsExtraPrice
                      hideTitle
                    />
                    <ToppingsPicker
                      title="Veggies"
                      toppingOptions={veggieToppingsOptions}
                      selectedToppings={selectedVeggieToppings}
                      onToppingsUpdate={handleAddVeggieToppings}
                      isPizzaSizeSplittable={isPizzaSizeSplittable}
                      extraPrice={toppingUpChargePrice}
                      numDisplayedItems={veggieToppingsOptions.length}
                      hideToppingsExtraPrice
                      hideTitle
                    />
                  </Grid>

                  <HeaderText margin="38px 0 24px" text="Sauce & cheese" />
                  <SauceCheeseFlavorSetterDesktop />
                </>
              )}
            </Grid>

            {!showEditBuilderSkeleton && <SpecialInstructionsSetterDesktop />}
            <CaloriesDisclaimer />
          </Grid>
        </Grid>
      </Grid>
    </CenteredContainer>
  );
};

export default PizzaBuilderDesktopExpanded;
