import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import {
  PizzaIngredientOption
} from '@/builders/pizza/dataTransformers/builderTypes';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import usePizzaSizeHardcoding from '../../../../../../hardcoding/utils/usePizzaSizeHardcoding';
import usePizzaSizePriceHardcoding from '../../../../../../hardcoding/utils/usePizzaSizePriceHardcoding';
import { RootState } from '@/rootStateTypes';
import { useCCGetDealBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery';
import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { getSizeUpchargeAmount } from '@/clientCore/menu/utils/pizzaPrices';

const useDealBuilderSizePricing = (sizes: PizzaIngredientOption[]): PizzaIngredientOption[] | undefined => {
  const deal = useSelector(dealSelectors.selectDeal);

  const pizzaId = useSelector(selectors.selectPizzaId);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const { loading, rawData } = useCCGetDealBuilderQuery({ itemId: deal?.data?.id, skip: !deal });

  if (deal?.data?.steps?.length !== 1 || !isYumEcomm || loading) {
    return undefined;
  }

  const selectedRecipe = rawData?.steps?.[0]?.recipes?.find((recipe) => recipe.id === pizzaId);
  const rawSizes = selectedRecipe?.options?.find((option) => option.id.includes('size'))?.modifiers;
  const newSizes: PizzaIngredientOption[] | undefined = rawSizes?.reduce((acc: PizzaIngredientOption[], rawSize) => {
    const sizeUpcharge = selectedRecipe?.options ? getSizeUpchargeAmount(rawSize, selectedRecipe?.options) : 0;
    const price = rawData?.price ? rawData.price + sizeUpcharge : 0;
    const size = sizes.find((transformedSize) => transformedSize.id === rawSize.id);
    if (size) {
      const updatedSize: PizzaIngredientOption = {
        ...size,
        price
      };
      acc.push(updatedSize);
    }
    return acc;
  }, []);

  return newSizes;
};

const useSizePricing = (sizes: PizzaIngredientOption[]) => {
  const updatedSizes = usePizzaSizePriceHardcoding(sizes);
  const { shouldShowSizePrice: shouldShowPriceHardcoding } = usePizzaSizeHardcoding();
  const dealSizes = useDealBuilderSizePricing(sizes);

  const [{ enabled: singleDealSizePriceEnabled }] = useDecision('fr-dtg-908-pizza-size-price-single-step-deals');
  const [{ enabled: isSizeDisplayOrderFlagEnabled }] = useDecision('fr-web-3546-pb-sizes-display-order');

  const shouldShowDealPrice = dealSizes && singleDealSizePriceEnabled;
  const shouldShowPrice = shouldShowPriceHardcoding || shouldShowDealPrice;
  const newSizes = shouldShowDealPrice ? dealSizes : updatedSizes;
  const sortedSizes = [...newSizes]?.sort((a, b) => (b?.priority ?? 0) - (a?.priority ?? 0));
  return {
    shouldShowSizePrice: shouldShowPrice,
    sizes: isSizeDisplayOrderFlagEnabled ? sortedSizes : newSizes
  };
};

export { useSizePricing };
