import { Grid, RadioGroup, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import RadioBox from '../../../../../coreComponents/boxes/RadioBox';
import formattedPrice from '../../../../../common/formattedPrice';
import { onPizzaSizeClick } from '../../../../../dataAnalytics/dataAnalyticsHelper';
import { SoldOutBadge } from '../../../../../coreComponents/boxes/Badge';
import styles from './SizesPicker.styles';
import {
  PizzaIngredient,
  PizzaIngredientOption
} from '@/builders/pizza/dataTransformers/builderTypes';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { useSizePricing } from './SizesPickerHooks';

interface SizesPickerProps {
  sizes: PizzaIngredientOption[];
  selectedSize: PizzaIngredient | null;
  onSelect: (size: null | PizzaIngredientOption) => void;
}

const SizesPicker = ({
  sizes,
  selectedSize,
  onSelect
}: SizesPickerProps): JSX.Element => {
  const classes = styles();

  const analytics = useAnalytics();
  const { pizza } = analytics.analyticsDataModel;
  const { isLineup } = useLineup();
  const dealType = useSelector(dealSelectors.selectDealType);
  const { sizes: updatedSizes, shouldShowSizePrice } = useSizePricing(sizes);

  const handleSizeOptionSelection = (size: null | PizzaIngredientOption) => () => {
    if (updatedSizes.length <= 1) return;
    if (size && !size.outOfStock) {
      analytics.push(() => onPizzaSizeClick(size.name, pizza.name ?? '', isLineup));
    }
    onSelect(size);
  };

  const getLowerText = (price: string): string => {
    if (!shouldShowSizePrice && dealType) {
      return '';
    }
    return price;
  };

  return (
    <>
      <Grid item data-testid="sizes-picker">
        <Typography variant="h3" id="size-picker">
          Size
        </Typography>
      </Grid>
      <Grid item container className={classes.sizesContainer}>
        <RadioGroup row>
          {updatedSizes.map((size: PizzaIngredientOption, index) => {
            const soldOut = size.outOfStock ?? false;
            const upperText = size.name;

            const lowerText = soldOut ? (
              <SoldOutBadge />
            ) : (
              getLowerText(formattedPrice(size.price))
            );
            const ariaLabel = `${upperText} ${
              soldOut ? 'sold out' : getLowerText(formattedPrice(size.price))
            }`;

            const checked = selectedSize?.id === size.id;

            return (
              <Fragment key={`size-${index}`}>
                <RadioBox
                  id={size.id}
                  tabIndex={-10}
                  className={classes.sizeBox}
                  upperText={upperText}
                  lowerText={lowerText}
                  ariaRadioLabel={ariaLabel}
                  radioValue={upperText}
                  checked={checked}
                  disabled={soldOut}
                  onSelect={handleSizeOptionSelection(checked ? null : size)}
                  isSoldOut={soldOut}
                />
              </Fragment>
            );
          })}
        </RadioGroup>
      </Grid>
    </>
  );
};

export default SizesPicker;
