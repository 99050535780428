import {
  PizzaBuilder, PromotionAction, PromotionDefinition, useGetPizzaBuilderQuery
} from '@pizza-hut-us-development/client-core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { useRouter } from 'next/router';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { transformPizzaBuilderPizza } from './transformPizzaBuilderPizza';
import { OccasionApi } from '@/localization/constants';
import { selectOccasion } from '@/localization/selectors';
import { PizzaBuilderOptions } from '@/graphql/dynamicQueries/pizza';
import { RootState } from '@/rootStateTypes';
import useProduct from '@/graphql/hooks/variables/useProductItem';
import ProductId from '@/common/ProductId';
import { useGetProductsToHideQuery } from '@/api/reduxToolkit/hideProducts';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';
import { sauceHardcodedConstant } from '@/clientCore/hardCodingHelpers/WEB-2491-yumPizzaNoSauce/helpers';
import { RedemptionQueryParams } from '@/clientCore/redemptionMenu/constants';
import { getPromotionDefinition } from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import Routes from '@/router/routes';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { openModal } from '@/localization/actions';

type CCPizzaBuilderTransformed = {
  data?: PizzaBuilderOptions;
  loading: boolean | null;
  error?: unknown | boolean | null;
  storeID: string | null;
  rawData?: PizzaBuilder;
};
export const useCCGetPizzaBuilderQuery = ({ itemId = '', skip = false }): CCPizzaBuilderTransformed => {
  const [promotionDefinition, setPromotionDefinition] = useState<PromotionDefinition>();
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const [storeNumber, setStoreNumber] = useState<string>('');
  const [storeTimezone, setStoreTimeZone] = useState<string | undefined>(undefined);

  const router = useRouter();
  const dispatch = useDispatch();
  const cart = useSelector(orderSelectors.cart);
  const [{ enabled: redemptionBuilderEnabled }] = useDecision('fr-web-3856-builder_promotion_support');

  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const { globalId } = new ProductId(itemId);
  const storeSpecificId = useProduct('items', itemId).variables?.itemID;
  const idToUse = isYumEcomm ? globalId : storeSpecificId;

  const { data: hideProductData } = useGetProductsToHideQuery();

  useEffect(() => {
    if (storeDetails?.storeNumber) setStoreNumber(storeDetails.storeNumber);
    if (storeDetails?.storeTimezone) setStoreTimeZone(storeDetails.storeTimezone);
  }, [storeDetails]);

  const flowId = router.query.fl?.toString();
  const redemptionCode = router.query.red?.toString();

  // Grab our associated promotion definition
  useEffect(() => {
    if (!cart || !redemptionCode || !redemptionBuilderEnabled) return;
    getPromotionDefinition(redemptionCode, cart, dispatch).then((res) => {
      if (!res) {
        // No promotion definition found or fetched
        dispatch(openModal({
          title: 'We’re sorry',
          body: 'Something went wrong. Please try again.',
          cta: {
            text: 'Ok',
            callback: () => router.push(Routes.DEALS)
          }
        }));
      }
      setPromotionDefinition(res);
    });
  }, [cart, redemptionCode, dispatch, router, redemptionBuilderEnabled]);

  const { data, isLoading, error } = useGetPizzaBuilderQuery(
    {
      storeNumber,
      itemId: idToUse,
      promotionDefinition: promotionDefinition ? {
        definition: promotionDefinition,
        action: flowId === RedemptionQueryParams.REQUIREMENTS ? PromotionAction.REQUIREMENTS : PromotionAction.EFFECTS
      } : undefined
    },
    { skip: !storeNumber || skip }
  );

  if (skip) {
    return {
      data: undefined,
      loading: false,
      error: null,
      storeID: null
    };
  }

  if (isLoading || !storeNumber) {
    return {
      data: undefined,
      loading: true,
      error: null,
      storeID: null
    };
  }

  const transformedBuilderPizza = data
    ? transformPizzaBuilderPizza(data, occasion, storeTimezone ?? '', isYumEcomm, hideProductData)
    : null;

  if (debugEnabled) {
    transformationDebugLogging(
      'useCCGetPizzaBuilderQuery',
      'useGetPizzaBuilderQuery',
      data,
      transformedBuilderPizza,
      debugMode,
      [DebugModeOptions.PRODUCTS, DebugModeOptions.MENU, DebugModeOptions.TRANSFORMATIONS]
    );
  }

  const addNoSauce = (transformedPizza?: PizzaBuilderOptions | null): PizzaBuilderOptions | undefined => {
    if (!transformedPizza) return undefined;
    const hasNoSauce = Boolean(transformedPizza?.sauces?.find((sauce) => sauce?.name?.toLowerCase() === 'no sauce'));
    if (!hasNoSauce) {
      const newTransformedPizza = { ...transformedPizza };
      if (
        'sauces' in newTransformedPizza
        && 'sauces' in transformedPizza
        && Boolean(transformedPizza.sauces?.length)
        && Boolean(newTransformedPizza.sauces?.length)
      ) newTransformedPizza?.sauces?.push(sauceHardcodedConstant(transformedPizza?.sauces?.length));
    }
    return transformedPizza;
  };

  return {
    data:
      (isYumEcomm ? addNoSauce(transformedBuilderPizza) : transformedBuilderPizza) ?? undefined,
    loading: isLoading,
    error,
    storeID: storeNumber,
    rawData: data
  };
};
