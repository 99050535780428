import { useSelector } from 'react-redux';
import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { PIZZA_MELT, PIZZA_MELT_CRUST } from '@/builders/pizza/constants';

function usePizzaMeltDetector(): boolean {
  const selectedCrust = useSelector(selectors.selectPizzaCrust);
  const isPizzaMelt = [PIZZA_MELT, PIZZA_MELT_CRUST]
    .some((crustName) => crustName.toLowerCase() === selectedCrust?.name?.toLowerCase());
  return isPizzaMelt;
}

export {
  usePizzaMeltDetector
};
