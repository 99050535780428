import { useSelector } from 'react-redux';
import { Modifier } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { useCCGetDealBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery';
import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { getCrustUpchargeAmount } from '@/clientCore/menu/utils/pizzaPrices';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import Routes from '@/router/routes';
import { useCCGetPizzaBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetPizzaBuilderQuery';
import { RootState } from '@/rootStateTypes';

interface PizzaPricing {
  crustUpcharges?: { upcharge: number; id: string }[] | undefined;
  toppingUpChargePrice: number;
  hasIncludedToppings: boolean;
}

const getToppingUpchargePrice = (selectedCrustOption: Modifier) => {
  const toppingUpchargeLimits = selectedCrustOption?.upcharges
    ?.find((toppingUpcharges) => toppingUpcharges.slotCode.toLowerCase().includes('topping'))
    ?.weightPriceRanges;

  const toppingUpChargePrice = toppingUpchargeLimits?.reduce((upCharge, priceRange) => {
    if (upCharge < priceRange.price.amount) {
      return priceRange.price.amount;
    }

    return upCharge;
  }, 0);

  return toppingUpChargePrice;
};

const getHasIncludedToppings = (selectedCrustOption: Modifier | undefined) => !!selectedCrustOption?.upcharges
  && !!selectedCrustOption.upcharges
    .find((upcharge) => upcharge?.slotCode.toLowerCase().includes('topping'))
    ?.weightPriceRanges
    .some((range) => range?.price?.amount === 0);

const usePizzaDealPricing = (): PizzaPricing => {
  const pizzaId = useSelector(selectors.selectPizzaId);
  const deal = useSelector(dealSelectors.selectDeal);
  const selectedSize = useSelector(selectors.selectPizzaSizeOption);
  const selectedCrust = useSelector(selectors.selectPizzaCrust);

  const { rawData } = useCCGetDealBuilderQuery({ itemId: deal?.data.id });

  if (!rawData) {
    return {
      crustUpcharges: undefined,
      toppingUpChargePrice: 0,
      hasIncludedToppings: false
    };
  }

  const selectedRecipe = rawData?.steps[0].recipes.find((recipe) => recipe.id === pizzaId);
  const selectedSizeOption = selectedRecipe?.options
    .find((findSize) => findSize.id.toLowerCase().includes('size'))
    ?.modifiers?.find((size) => size.id === selectedSize.id);
  const crustUpcharges = selectedSizeOption?.modifiers?.map((crust) => ({
    upcharge: selectedRecipe?.options ? getCrustUpchargeAmount(crust, selectedRecipe.options) : 0,
    id: crust.id
  }));
  const selectedCrustOption = selectedSizeOption?.modifiers?.find((crust) => crust.id === selectedCrust?.id);
  const toppingUpChargePrice = selectedCrustOption ? getToppingUpchargePrice(selectedCrustOption) : 0;
  const hasIncludedToppings = getHasIncludedToppings(selectedCrustOption);

  return {
    crustUpcharges,
    toppingUpChargePrice: toppingUpChargePrice || 0,
    hasIncludedToppings
  };
};

const usePizzaMenuPricing = (): PizzaPricing => {
  const pizzaId = useSelector(selectors.selectPizzaId);
  const selectedCrust = useSelector(selectors.selectPizzaCrust);

  const { rawData } = useCCGetPizzaBuilderQuery({ itemId: pizzaId });

  if (!rawData) {
    return {
      toppingUpChargePrice: 0,
      hasIncludedToppings: false
    };
  }
  const selectedCrustOption = rawData?.sizesAndCrusts
    .flatMap((size) => size?.modifiers)
    .find((crust) => crust?.id === selectedCrust?.id);
  const toppingUpChargePrice = selectedCrustOption ? getToppingUpchargePrice(selectedCrustOption) : 0;
  const hasIncludedToppings = getHasIncludedToppings(selectedCrustOption);

  return {
    toppingUpChargePrice: toppingUpChargePrice || 0,
    hasIncludedToppings
  };
};

const useNoPricing = (): PizzaPricing => ({
  toppingUpChargePrice: 0,
  hasIncludedToppings: false
});

export const usePizzaPricing = (currentPath?: string): PizzaPricing => {
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [{ enabled: newToppingUpchargeEnabled }] = useDecision('fr-dtg-1133-crust-upcharges-topping-upcharges');
  let useNewPizzaPricing = useNoPricing;

  if (newToppingUpchargeEnabled && isYumEcomm) {
    useNewPizzaPricing = currentPath === Routes.DEALS ? usePizzaDealPricing : usePizzaMenuPricing;
  }
  const { crustUpcharges, toppingUpChargePrice, hasIncludedToppings } = useNewPizzaPricing();

  return {
    crustUpcharges,
    toppingUpChargePrice,
    hasIncludedToppings
  };
};
