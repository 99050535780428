import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { Box } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import useStateFromProp from '../../../../common/useStateFromProp';
import { KEYBOARD_ENTER_KEYCODE, ENTER_KEY_EVENT_CODE } from '../../../../common/constants';
import { DefaultImage } from '../../../../homepage/sidekicks/constants';

import useNewToppingBadge from '../Toppings/ToppingsPicker/hooks/useNewToppingBadge';
import NewBadge from '@/coreComponents/boxes/Badge/NewBadge';
import { usePizzaMeltDetector } from '@/hooks/usePizzaMeltDetector';

type ProvidedAriaRole = {
  role: string;
  'aria-expanded'?: boolean;
  'aria-checked'?: boolean;
};

export enum PickerType {
  Cheese = 'cheese',
  Sauce = 'sauce',
  Topping = 'topping-picker'
}

interface StyleProps {
  card: string;
  cardText: string;
  cardAction: string;
  image: string;
  expandedContainer: string;
  collapse: string;
  cardAvatar?: string;
}

interface Props {
  expandButton: (expanded: boolean) => JSX.Element | null;
  expandedContent?: JSX.Element;
  onToggle?: (expanded: boolean) => void;
  onExpanded?: () => void;
  onCollapsed?: () => void;
  pushSCCFEditButtonAnalytics?: (expanded: boolean) => void;
  classes: StyleProps;
  testId: string;
  imgUrl: string | undefined;
  heading: string | JSX.Element | null | undefined;
  id?: string | undefined;
  subheading: string | JSX.Element | undefined;
  startsExpanded?: boolean;
  ariaDescribedby?: string;
  enabled?: boolean;
  className?: string;
  providedAriaRole?: (prop: boolean) => ProvidedAriaRole;
  pickerType?: PickerType | undefined;
  editable?: boolean;
}

const ExpandablePicker = ({
  startsExpanded = false,
  enabled = true,
  onToggle,
  onExpanded,
  onCollapsed,
  expandButton,
  pushSCCFEditButtonAnalytics,
  heading,
  id,
  subheading,
  expandedContent,
  classes,
  testId,
  imgUrl,
  ariaDescribedby,
  providedAriaRole,
  pickerType,
  editable = true
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useStateFromProp<boolean>(startsExpanded);
  const cardRef = useRef(null);

  const isPizzaMelt = usePizzaMeltDetector();
  const isCheesePicker = pickerType === PickerType.Cheese;
  const isNewTopping = useNewToppingBadge(id as string);

  const toggle = () => {
    if (onToggle) onToggle(!expanded);
    if (enabled) {
      setExpanded(!expanded);
      if (pushSCCFEditButtonAnalytics) pushSCCFEditButtonAnalytics(!expanded);
    }
  };

  const handleToggleCard = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (!editable) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    const isCard = cardRef.current === event.target;
    if (isCard) {
      toggle();
    }
  };

  const onEntered = () => {
    if (onExpanded) onExpanded();
  };

  const onExited = () => {
    if (onCollapsed) onCollapsed();
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const isEnterKey = event.key === ENTER_KEY_EVENT_CODE
      || event.keyCode === KEYBOARD_ENTER_KEYCODE;

    if (isEnterKey) {
      handleToggleCard(event);
    }
  };

  useEffect(() => {
    if (!enabled) {
      setExpanded(false);
    }
  }, [enabled, setExpanded]);

  const ariaRole = providedAriaRole && providedAriaRole(expanded);
  const showEditableContent = (): boolean => {
    if (isPizzaMelt) {
      return !isCheesePicker;
    }
    return editable;
  };

  const shouldShowExpandedContent = editable && (!isPizzaMelt || (isPizzaMelt && pickerType === PickerType.Sauce));

  return (
    <Card
      ref={cardRef}
      onClick={handleToggleCard}
      onKeyDown={handleOnKeyDown}
      style={{ cursor: editable ? 'pointer' : 'default' }}
      className={classes.card}
      data-testid={testId}
      tabIndex={0}
    >
      <CardHeader
        {...ariaRole}
        aria-describedby={!expanded ? ariaDescribedby : undefined}
        onClick={toggle}
        style={{ padding: 0 }}
        classes={{
          title: classes.cardText,
          subheader: classes.cardText,
          avatar: classes.cardAvatar,
          action: classes.cardAction
        }}
        avatar={(
          <Avatar
            className={classes.image}
            variant="square"
            src={imgUrl}
            alt=""
          >
            <CardMedia
              component="img"
              image={DefaultImage?.DESKTOP_SMALL_IMAGE}
              alt=""
              title="title"
              className={classes.image}
            />
          </Avatar>
        )}
        action={showEditableContent() && expandButton(expanded)}
        title={(
          <Box display="flex" style={{ gap: '8px' }}>
            {isNewTopping && <NewBadge />}
            <Box data-testid={pickerType}>{heading}</Box>
          </Box>
        )}
        subheader={subheading}
      />
      {shouldShowExpandedContent
        && (
          <Collapse
            onEntered={onEntered}
            onExited={onExited}
            in={expanded}
            classes={{ wrapperInner: classes.expandedContainer }}
            className={classes.collapse}
          >
            <span data-testid="expanded-picker-content">{expandedContent}</span>
          </Collapse>
        )}
    </Card>
  );
};

export default ExpandablePicker;
