import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors,
  updatePizzaCheese,
  updatePizzaSauce
} from '../../../../slice/pizza.slice';
import CheesePicker from '../../CheesePicker';
import SaucePicker from '../../SaucePicker';
import toIngredient from '../../../../dataTransformers/toIngredient';
import { PizzaIngredientOption } from '@/builders/pizza/dataTransformers/builderTypes';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '-26px'
  },
  pickerSpacing: {
    marginBottom: '26px',
    maxWidth: '400px'
  }
}));

const SauceCheeseFlavorSetterDesktop = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSauceSelect = (selectedSauce: PizzaIngredientOption | undefined) => {
    if (!selectedSauce) return;
    const ingredient = toIngredient(selectedSauce);
    if (!ingredient) return;
    dispatch(updatePizzaSauce(ingredient));
  };

  const onCheeseSelect = (selectedCheese: PizzaIngredientOption) => {
    const ingredient = toIngredient(selectedCheese);
    if (ingredient) dispatch(updatePizzaCheese(ingredient));
  };

  const sauces = useSelector(selectors.selectSauceOptions);
  const cheeses = useSelector(selectors.selectCheeseOptions);
  const selectedSauce = useSelector(selectors.selectPizzaSauceOption);
  const selectedCheese = useSelector(selectors.selectPizzaCheeseOption);

  return (
    <Grid container direction="column" className={classes.root}>
      {sauces && (
      <Grid className={classes.pickerSpacing}>
        <SaucePicker
          sauces={sauces}
          selectedSauce={selectedSauce || sauces[0]}
          onSelect={onSauceSelect}
        />
      </Grid>
      )}
      <Grid className={classes.pickerSpacing}>
        <CheesePicker
          onSelect={onCheeseSelect}
          cheeses={cheeses}
          selectedCheese={selectedCheese}
        />
      </Grid>
    </Grid>
  );
};

export default SauceCheeseFlavorSetterDesktop;
