import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { TAVERN_PIZZA } from '@/builders/pizza/constants';
import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { RootState } from '@/rootStateTypes';

const usePizzaSizeHardCoding = () => {
  const [{ enabled: tavernEnabled }] = useDecision('cb-tavern_national_launch', { autoUpdate: true });
  const [{ enabled: newPricePointEnabled, variables: pricingVariables }] = useDecision('cb-show_new_price_points', { autoUpdate: true });
  const [{ enabled: hidePizzaDealPricingEnabled }] = useDecision('fr-web-3543-pizza-deal-pricing');

  const selectedRecipe = useSelector(selectors.selectPizzaOptions);

  const shouldShowSizePrice = tavernEnabled && newPricePointEnabled
    && selectedRecipe?.summary.name?.toLowerCase().includes(TAVERN_PIZZA.toLowerCase());

  const deal = useSelector(dealSelectors.selectDeal);
  const dealType = deal?.data?.type;
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  if (isYumEcomm && dealType && hidePizzaDealPricingEnabled) {
    return {
      shouldShowSizePrice: Boolean(!dealType)
    };
  }

  // for Yum-ecomm we cannot rely on the deal codes or product id's as they are different from QO menu
  if (isYumEcomm && pricingVariables?.hide_tavern_pricing) {
    // will rely on the deal name and hide the pricing for the recipes and pizza sizes
    const isTavernDeal = deal?.data?.name?.toLowerCase().includes(TAVERN_PIZZA.toLowerCase());
    return {
      shouldShowSizePrice: Boolean(!isTavernDeal)
    };
  }

  return { shouldShowSizePrice };
};

export default usePizzaSizeHardCoding;
