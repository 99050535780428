import React, { ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import { capitalize } from '@/utils';
import fontStyles from '../../../../../common/fontStyles';
import colors from '../../../../../common/colors';
import SCCFPicker from '../../SCCFPicker';
import PortionPicker from '../PortionPicker';
import { useIngredientNutrition } from '../../../hooks/pizzaIngredientOptionsSelectors';
import { useExtraCheesePricing } from '../../../hooks/pizzaPricing';
import { joinText } from '@/common/string-formatter';
import Badge from '../../../../../coreComponents/boxes/Badge';
import { onPizzaCheeseOptionsClick } from '@/dataAnalytics/dataAnalyticsHelper';
import formattedPrice from '../../../../../common/formattedPrice';
import { selectors } from '../../../slice/pizza.slice';
import { SCCFSection } from '@/dataAnalytics/analyticsTypes';
import {
  IngredientOptionWithPortions,
  PizzaIngredientOption,
  PortionChoice
} from '@/builders/pizza/dataTransformers/builderTypes';
import Portion from '@/common/Portion';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import { getAvailablePortions } from '../../utils';
import {
  DETROIT_PIZZA,
  YUM_EXTRA_CHEESE_MODIFIER_WEIGHT_CODE, YUM_MODIFIER_PIZZA_CHEESE,
  YUM_SLOT_PIZZA_CHEESE
} from '@/builders/pizza/constants';
import { RootState } from '@/rootStateTypes';
import { PickerType } from '@/builders/pizza/setters/ExpandablePicker/ExpandablePicker';
import { usePizzaMeltDetector } from '@/hooks/usePizzaMeltDetector';

const useStyles = makeStyles(() => ({
  title: {
    ...fontStyles.textLink,
    ...fontStyles.h3Mobile
  },
  whitespace: {
    marginBottom: '7px'
  },
  card: {
    height: '64px'
  },
  cardText: {
    ...fontStyles.bodyPriceAndCalorie
  },
  expandElement: {
    ...fontStyles.bodyPriceAndCalorie,
    color: colors.blue
  },
  image: {
    height: '64px',
    width: '64px'
  },
  outOfStockTitle: {
    paddingLeft: '16px',
    '& > .badgeText': {
      display: 'inline-block',
      marginLeft: '5px'
    }
  },
  fullWidth: {
    width: '100%'
  },
  badgeRoot: {
    whiteSpace: 'break-spaces',
    width: 88,
    textAlign: 'center'
  },
  extraChargeBadgeHeight: {
    height: 33
  },
  addPriceBadgeHeight: {
    height: 18
  }
}));

export interface CheesePickerProps {
  cheeses: IngredientOptionWithPortions[];
  selectedCheese: PizzaIngredientOption | undefined;
  onSelect: (cheese: PizzaIngredientOption) => void;
}

const CheesePicker = ({ onSelect, cheeses, selectedCheese }: CheesePickerProps): ReactElement => {
  const classes = useStyles();
  const sizeCrustSelected = useSelector(selectors.isCrustAndSizeSelected);
  const currentCrust = useSelector(selectors.selectPizzaCrustOption);
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const extraCheesePrice = useExtraCheesePricing();
  const isPizzaMelt = usePizzaMeltDetector();

  const cheesePrice = cheeses.find((cheese) => cheese.portions
    .find((portion) => portion.name === selectedCheese?.name))?.portions
    .find((portion) => portion.price !== 0)?.price;

  const cheeseUpcharge = currentCrust && currentCrust.upcharges ? currentCrust.upcharges.find(
    (cheese) => cheese.slotCode === YUM_SLOT_PIZZA_CHEESE
  )?.modifiers?.find(
    (mod) => mod.modifierCode === YUM_MODIFIER_PIZZA_CHEESE
  )?.weights?.find(
    (weight) => weight?.modifierWeightCode?.includes(YUM_EXTRA_CHEESE_MODIFIER_WEIGHT_CODE)
  )?.price.amount : undefined;

  const cheeseUpchargePrice = isYumEcomm ? cheeseUpcharge : extraCheesePrice;

  const shouldDisplayExtraPrice = (isYumEcomm && sizeCrustSelected && cheesePrice) || sizeCrustSelected;
  const extraBadgeText = shouldDisplayExtraPrice
    ? `ADD ${formattedPrice(cheeseUpchargePrice)}`
    : 'EXTRA CHARGE';
  const { isLineup } = useLineup();
  const badgeClass = shouldDisplayExtraPrice
    ? classes.addPriceBadgeHeight : classes.extraChargeBadgeHeight;

  const analytics = useAnalytics();
  const { pizza } = analytics.analyticsDataModel;

  const selectedItem = selectedCheese?.portion !== Portion.NONE
    ? joinText([capitalize(SCCFSection.CHEESE), selectedCheese?.portion])
    : selectedCheese?.name;

  const addAnalytics = (portion: PortionChoice | undefined, isExpanded: boolean) => {
    if (portion) {
      analytics.push(() => onPizzaCheeseOptionsClick(
        pizza.name ?? '',
        capitalize(portion),
        isExpanded,
        isLineup
      ));
    }
  };

  const handleSelectCheese = (event: unknown, selectedPortion: PortionChoice) => {
    addAnalytics(selectedPortion, true);
    const newSelectedCheeses = cheeses?.map(
      ({ portions }) => portions.find(
        ({ portion }) => portion === selectedPortion
      )
    ).filter(Boolean);

    if (newSelectedCheeses.length > 0) {
      const newSelectedCheese = newSelectedCheeses[0];
      if (newSelectedCheese) onSelect({ ...newSelectedCheese });
    }
  };

  const onCollapsed = () => {
    addAnalytics(selectedCheese?.portion, false);
  };

  const isDetroitStyle = currentCrust?.name === DETROIT_PIZZA;
  const portions = isDetroitStyle ? [Portion.REGULAR] : getAvailablePortions(cheeses);
  const showBadge = portions.find((portion) => portion === Portion.EXTRA);

  return (
    <>
      <Grid item className={classes.whitespace}>
        <Typography variant="h3">Cheese</Typography>
      </Grid>
      <SCCFPicker
        pickerType={PickerType.Cheese}
        testId="cheese-picker"
        nutrition={useIngredientNutrition(selectedCheese?.nutrition, selectedCheese?.portion)}
        selectedItem={selectedItem}
        imgUrl={selectedCheese?.image}
        onCollapsed={onCollapsed}
        label={SCCFSection.CHEESE}
        editable={!isPizzaMelt && portions.length > 1}
      >
        <Grid container alignItems="flex-end" direction="column">
          <Grid item classes={{ root: classes.fullWidth }}>
            {portions.length > 1 && (
              <PortionPicker
                onSelection={handleSelectCheese}
                portions={portions}
                selectedPortion={selectedCheese?.portion || portions[0]}
                label="cheese"
              />
            )}
          </Grid>
          <Grid item>
            {showBadge && (
              <Badge
                className={`${classes.badgeRoot} ${badgeClass}`}
                text={extraBadgeText}
              />
            )}
          </Grid>
        </Grid>
      </SCCFPicker>
    </>
  );
};

export default CheesePicker;
